import request from '@/utils/request'


// 查询标签列表
export function listTag(query) {
  return request({
    url: '/dynamic/tag/list',
    method: 'get',
    params: query
  })
}

// 查询标签分页
export function pageTag(query) {
  return request({
    url: '/dynamic/tag/page',
    method: 'get',
    params: query
  })
}
export function changeIsOpen(data) {
  return request({
    url: '/dynamic/tag/changeIsOpen',
    method: 'post',
    data: data
  })
}
// 查询标签详细
export function getTag(data) {
  return request({
    url: '/dynamic/tag/detail',
    method: 'get',
    params: data
  })
}

// 新增标签
export function addTag(data) {
  return request({
    url: '/dynamic/tag/add',
    method: 'post',
    data: data
  })
}

// 修改标签
export function updateTag(data) {
  return request({
    url: '/dynamic/tag/edit',
    method: 'post',
    data: data
  })
}

// 删除标签
export function delTag(data) {
  return request({
    url: '/dynamic/tag/delete',
    method: 'post',
    data: data
  })
}
