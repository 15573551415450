import request from '@/utils/request'


// 查询ai申请记录列表
export function listAiApply(query) {
  return request({
    url: '/user/user-ai-applys/list',
    method: 'get',
    params: query
  })
}

// 查询ai申请记录分页
export function pageAiApply(query) {
  return request({
    url: '/user/user-ai-applys/page',
    method: 'get',
    params: query
  })
}

// 查询ai申请记录详细
export function getAiApply(data) {
  return request({
    url: '/user/user-ai-applys/detail',
    method: 'get',
    params: data
  })
}

// 新增ai申请记录
export function addAiApply(data) {
  return request({
    url: '/user/user-ai-applys/add',
    method: 'post',
    data: data
  })
}

// 修改ai申请记录
export function updateAiApply(data) {
  return request({
    url: '/user/user-ai-applys/edit',
    method: 'post',
    data: data
  })
}

// 删除ai申请记录
export function delAiApply(data) {
  return request({
    url: '/user/user-ai-applys/delete',
    method: 'post',
    data: data
  })
}
