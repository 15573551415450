import request from '@/utils/request'


// 查询猜你想搜列表
export function listSearch(query) {
  return request({
    url: '/dynamic/home-searchs/list',
    method: 'get',
    params: query
  })
}

// 查询猜你想搜分页
export function pageSearch(query) {
  return request({
    url: '/dynamic/home-searchs/page',
    method: 'get',
    params: query
  })
}

// 查询猜你想搜详细
export function getSearch(data) {
  return request({
    url: '/dynamic/home-searchs/detail',
    method: 'get',
    params: data
  })
}
export function changeIsOpen(data) {
  return request({
    url: '/dynamic/home-searchs/changeIsOpen',
    method: 'post',
    data: data
  })
}
// 新增猜你想搜
export function addSearch(data) {
  return request({
    url: '/dynamic/home-searchs/add',
    method: 'post',
    data: data
  })
}

// 修改猜你想搜
export function updateSearch(data) {
  return request({
    url: '/dynamic/home-searchs/edit',
    method: 'post',
    data: data
  })
}

// 删除猜你想搜
export function delSearch(data) {
  return request({
    url: '/dynamic/home-searchs/delete',
    method: 'post',
    data: data
  })
}
