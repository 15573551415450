<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="观看数" prop="lookNum" >-->
<!--        <a-input v-model="form.lookNum" placeholder="请输入观看数" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="剧名" prop="videoName" >
        <a-input show-count   v-model="form.videoName" placeholder="请输入剧名" />
      </a-form-model-item>


      <a-form-model-item label="英文剧名" prop="videoNameEn" >
        <a-input show-count   v-model="form.videoNameEn" placeholder="请输入英文剧名" />
      </a-form-model-item>


      <a-form-model-item label="封面图" prop="coverUrl" >
<!--        <a-input v-model="form.coverUrl" placeholder="请输入封面图" />-->

        <!--          :customRequest="handleUpload"-->
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.coverUrl"
          :showUploadList="false"
          action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"
          :beforeUpload="beforeUpload"
          :customRequest="handleChange"
        >
          <plus-outlined />
          <img v-if="form.coverUrl" :src="form.coverUrl" alt="logo"
               style="height:104px;max-width:300px"/>
          <div v-else>

            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="总集数" prop="totalNum" >
        <a-input-number :min="1" v-model="form.totalNum" placeholder="请输入总集数" />
      </a-form-model-item>

      <a-form-model-item label="排序(越小越靠前)" prop="seqSort" >
        <a-input-number :min="0" v-model="form.seqSort" placeholder="请输入排序" />
      </a-form-model-item>

      <a-form-model-item label="是否上映" prop="isRelease" >
        <a-select placeholder="请选择是否上映" @change="updateIsGo" v-model="form.isRelease">
          <a-select-option :key="0">否</a-select-option>
          <a-select-option :key="1">是</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item v-if="'0'==this.isGo"  label="上映时间" prop="releaseTime" >
        <a-date-picker  :allow-clear="true" size="default" v-model="form.releaseTime" placeholder="上映时间" value-format="YYYY-MM-DD HH:mm" format="YYYY-MM-DD HH:mm">
        </a-date-picker>
      </a-form-model-item>

      <a-form-model-item label="新增分类(此处输入，保存后下次会在下方列表中出现)" >
        <a-input v-model="form.tag"  placeholder="请输入新增分类(多个请使用，分割)" />
      </a-form-model-item>

      <a-form-model-item label="分类" prop="tagIds" >
<!--        <a-input v-model="form.tagIds" placeholder="请输入标签ids" />-->
<!--        <a-tree-select-->
<!--          v-model="tagIds"-->
<!--          style="width: 100%"-->
<!--          :tree-data="treeData"-->
<!--          tree-checkable-->
<!--          search-placeholder="请选择分类"-->
<!--          @change="changeSelect"-->
<!--        />-->
        <a-select
          v-model="tagIds"
          mode="multiple"
          placeholder="请选择"
        >
        <a-select-option v-for="(d, index) in treeData"  :key="index" :value="d.value">
          {{ d.title }}
        </a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="前多少集可以使用金币观看" prop="limitNum" >-->
<!--        <a-input-number :min="0" v-model="form.limitNum" placeholder="请输入" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="前多少集可以免费观看" prop="freeLimitNum" >
        <a-input-number :min="0" v-model="form.freeLimitNum" placeholder="请输入" />
      </a-form-model-item>


      <a-form-model-item label="每集钻石价格" prop="diamondPrice" >
        <a-input-number :min="0" v-model="form.diamondPrice" placeholder="请输入" />
      </a-form-model-item>
<!--      <a-form-model-item label="每集金币价格" prop="goldPrice" >-->
<!--        <a-input-number :min="0" v-model="form.goldPrice" placeholder="请输入" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="简介" prop="introductions" >
        <a-textarea show-count  :maxLength='500' v-model="form.introductions" placeholder="请输入简介" />
      </a-form-model-item>

      <a-form-model-item label="英文简介" prop="introductionsEn" >
        <a-textarea show-count  :maxLength='500' v-model="form.introductionsEn" placeholder="请输入英文简介" />
      </a-form-model-item>


<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVideo, addVideo, updateVideo } from '@/api/video/video'
import { listTag } from '@/api/video/tag'
import UploadFileToVOD from "@/api/vod.js"
import { uploadCos } from '@/api/common/index'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      isGo: false,
      tagIds:[],
      tag: '',
      // 表单参数
      form: {
        id: null,

        lookNum: null,
        seqSort: null,

        limitNum: null,

        diamondPrice: null,

        goldPrice: null,

        introductions: null,
        introductionsEn: null,

        tagIds: null,

        totalNum: null,
        releaseTime: null,
        videoName: null,
        videoNameEn: null,

        coverUrl: null,

        createTime: null,
        freeLimitNum: 0,
        remark: null,
        isRelease:1
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      uploadLoading:false,
      treeData: [],
      rules: {
        lookNum: [
          { required: true, message: '观看数不能为空' }
        ],
        limitNum: [
          { required: true, message: '前多少集可以使用金币观看不能为空' }
        ],
        // freeLimitNum: [
        //   { required: true, message: '前多少集可以免费观看不能为空' }
        // ],
        // tagIds: [
        //   { required: true, message: '标签不能为空' }
        // ],
        totalNum: [
          { required: true, message: '总集数不能为空' }
        ],
        releaseTime: [
          { required: true, message: '请选择上映时间' }
        ],
        videoName: [
          { required: true, message: '剧名不能为空' },

        ],
        videoNameEn: [
          { required: true, message: '英文剧名不能为空' },

        ],
        introductions: [
          {max: 500, message: '简介最多500字'}
        ],
        introductionsEn: [
          {max: 500, message: '简介最多500字'}
        ],
        coverUrl: [
          { required: true, message: '' }
        ]
      }
    }
  },
  filters: {
  },
  created () {

  },
  computed: {
  },
  watch: {
  },
  mounted () {
    this.getAllList();
  },
  methods: {
    updateIsGo(value) {
      this.isGo=value
    },

    handleChange(info) {

      let that = this
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          that.form.coverUrl = fileName[0];//文件名
          that.uploadLoading = false;
        },

      });
      that.uploadLoading = false;

    },
    beforeUpload: function (file) {
      var fileType = file.type;
      if (fileType.indexOf('image') < 0) {
        this.$message.warning('请上传图片');
        this.uploadLoading=false
        return false;
      }
      this.form.coverUrl=""
      //TODO 驗證文件大小
    },
    handleUpload(info) { //上传主图(一张图)
      console.log(info)
      let that = this;
      this.form.coverUrl = "";
      this.uploadLoading = true;
      uploadCos(info.file)
    //  , function (fileName) {
    //   console.log('--------------------------------')
    //   console.log(fileName)
    //   console.log('--------------------------------')
    //   that.uploadLoading = false;
    //   that.form.coverUrl = fileName;
    // }
    },
    changeSelect(e,label,extra) {
      this.tagIds = e;

      this.form.tagIds = e.join(',')
    },
    getAllList() {
      listTag().then(res => {
        console.log(res.data)
        this.treeData = res.data.map(i => ({
          title:i.tagTitle,
          value:i.id,
          isOpen:i.isOpen,
          key:i.id
        }))
      })
    },
    onClose () {
      this.tagIds=null
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        lookNum: null,
        limitNum: null,
        diamondPrice: null,
        goldPrice: null,
        introductions: null,
        introductionsEn: null,
        tagIds: [],
        totalNum: null,
        videoName: null,
        videoNameEn: null,
        coverUrl: null,
        createTime: null,
        remark: null,
        tag: null,
        isRelease:1,
        releaseTime: null,
        freeLimitNum: null,
      }

      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.getAllList()
      this.open = true

      this.form={
        id: null,

        lookNum: null,

        limitNum: null,

        diamondPrice: null,

        goldPrice: null,

        introductions: null,
        introductionsEn: null,

        tagIds: null,

        totalNum: null,

        videoName: null,
        videoNameEn: null,

        coverUrl: null,

        createTime: null,

        remark: null,
        isRelease:1
      }
      this.updateIsGo(1)
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.getAllList()
      this.formType = 2
      const id = row ? row.id : ids
      getVideo({"id":id}).then(response => {
        this.form = response.data
        this.isGo=response.data.isRelease
        if(response.data.tagIds) {
          this.tagIds = response.data.tagIds.split(',')
        } else {
          this.tagIds =null;
        }
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          this.form.tagIds=this.tagIds.join(',')
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVideo(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.tagIds=null
              this.$emit('ok')

            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addVideo(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.tagIds=null
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
