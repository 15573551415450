<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="举报人昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入举报人昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="被举报人昵称" prop="reportNickname">
                <a-input v-model="queryParam.reportNickname" placeholder="请输入被举报人昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="审核状态" prop="auditStatus">
                  <a-select placeholder="审核状态" v-model="queryParam.auditStatus">
                    <a-select-option :key="0">未审核</a-select-option>
                    <a-select-option :key="1">通过</a-select-option>
                    <a-select-option :key="2">拒绝</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="审核反馈内容" prop="auditReason">
                  <a-input v-model="queryParam.auditReason" placeholder="请输入审核反馈内容" allow-clear/>
                </a-form-item>
              </a-col>


              <a-col :md="8" :sm="24">
                <a-form-item label="举报人ShowId" prop="showId">
                  <a-input v-model="queryParam.showId" placeholder="请输入举报人id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="被举报人ShowId" prop="reportShowId">
                  <a-input v-model="queryParam.reportShowId" placeholder="请输入被举报人id" allow-clear/>
                </a-form-item>
              </a-col>

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['dynamic:report:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['dynamic:report:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['dynamic:report:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['dynamic:report:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a   v-if="record.auditStatus===0" @click="$refs.createForm.handleUpdate(record, undefined)" >审核</a>
          <a   v-if="record.auditStatus===1 || record.auditStatus===2" @click="$refs.createForm.handleUpdate(record, undefined)" >详情</a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageReport,listReport, delReport } from '@/api/dynamic/report'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Report',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        reportUserId: null,
        dataType: null,
        nickname: null,
        reportNickname: null,
        reportShowId: null,
        showId: null,
        dataId: null,
        reportType: null,
        reportImg: null,
        reportContent: null,
        auditStatus: null,
        auditReason: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '主键',
        //   dataIndex: 'id',
        //   
        //   align: 'center'
        // },
        {
          title: '举报人昵称',
          dataIndex: 'nickname',
          
          align: 'center'
        },
        {
          title: '举报人showId',
          dataIndex: 'showId',

          align: 'center'
        },
        {
          title: '被举报人昵称',
          dataIndex: 'reportNickname',
          
          align: 'center'
        },
        {
          title: '被举报人showId',
          dataIndex: 'reportShowId',

          align: 'center'
        },

        // {
        //   title: '举报类型',
        //   dataIndex: 'reportType',
        //   align: 'center'
        // },
        // {
        //   title: '举报图片',
        //   dataIndex: 'reportImg',
        //   align: 'center'
        // },
        {
          title: '举报内容',
          dataIndex: 'reportContent',
          align: 'center'
        },
        {
          title: '审核状态',
          dataIndex: 'auditStatus',
          customRender: function (t, r, index) {
            if(t == 0) {
              return '待审核'
            } else if(t == 1) {
              return '通过'
            } else{
              return '拒绝'
            }
          },
          align: 'center'
        },
        // {
        //   title: '审核反馈内容',
        //   dataIndex: 'auditReason',
        //   align: 'center'
        // },
        {
          title: '举报时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询举报列表 */
    getList () {
      this.loading = true
     pageReport(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        reportUserId: undefined,
        dataType: undefined,
        dataId: undefined,
        reportType: undefined,
        reportImg: undefined,
        reportContent: undefined,
        auditStatus: undefined,
        auditReason: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delReport(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('dynamic/report/export', {
            ...that.queryParam
          }, `举报_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
