<template>
  <a-modal width="40%" :label-col="4" :maskClosable="false" :wrapper-col="14" :visible="open" @cancel="onClose">



    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户昵称" prop="nickname" >
        <a-input v-model="form.nickname" disabled />
      </a-form-model-item>
      <a-form-model-item label="内容" prop="content" >
        <a-textarea disabled  v-model="form.content" />
      </a-form-model-item>


      <div  v-if="!form.files">
        <a-form-model-item
          label="图片"
          prop="files"
        >
        </a-form-model-item>
      </div>

      <div  v-if="form.files">
      <a-form-model-item
        label="图片"
        prop="files"
        disabled
        :key="index"
      >
        <a-row :gutter="16">
          <a-col :span="3" v-for="(image, index) in form.files.split(',')" :key="index">
            <img :src="image" v-image-preview   alt="图片" style="width: 100px; height: 100px; " />
          </a-col>
        </a-row>
      </a-form-model-item>
      </div>



      <a-form-model-item label="标签名称" prop="tagTitle" >
        <a-input disabled v-model="form.tagTitle" placeholder="请输入标签名称" />
      </a-form-model-item>
<!--      <a-form-model-item label="省" prop="province" >-->
<!--        <a-input disabled v-model="form.province" placeholder="请输入省" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="市" prop="city" >-->
<!--        <a-input disabled v-model="form.city" placeholder="请输入市" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="区/县" prop="area" >-->
<!--        <a-input disabled v-model="form.area" placeholder="请输入区/县" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="详细地址" prop="address" >-->
<!--        <a-input disabled v-model="form.address" placeholder="请输入详细地址" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="状态" prop="state" >
        <a-select v-model="form.state" @change="editState">
          <a-select-option key="0">待审核</a-select-option>
          <a-select-option key="1">上架</a-select-option>
          <a-select-option key="2">下架</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="拒绝原因" prop="rejectCause" >
        <a-input :disabled="this.state==0 || this.state==1"  v-model="form.rejectCause" placeholder="请输入拒绝原因" ></a-input>
      </a-form-model-item>

      <div v-if="rejectCause">
        历史驳回原因
        <a-list size="small" bordered v-for="(items, index) in rejectCause.split(',') ">
          <template>
            <a-list-item>{{ items }}</a-list-item>
          </template>
        </a-list>
      </div>

<!--      <a-form-model-item label="视频时长" prop="duration" >-->
<!--        <a-input v-model="form.duration" placeholder="请输入视频时长" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="量" prop="viewTotal" >-->
<!--        <a-input v-model="form.viewTotal" placeholder="请输入浏览量" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getDynamic, addDynamic, updateDynamic } from '@/api/dynamic/dynamic'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor,
  },
  data () {
    return {
      submitLoading: false,
      rejectCause: null,
      formTitle: '',
      state: 0,
      // 表单参数
      form: {
        id: null,

        userId: null,

        type: null,

        content: null,

        files: null,

        cover: null,

        tagId: null,

        tagTitle: null,

        location: null,

        province: null,

        city: null,

        area: null,

        address: null,

        state: null,

        rejectCause: null,

        duration: null,

        viewTotal: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '1-图文 2-视频不能为空', trigger: 'change' }
        ],
        content: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    editState(value) {
      this.state = value
      this.$forceUpdate()
    },
    // 表单重置
    reset () {
      this.formType = 1

      this.form = {
        id: null,
        userId: null,
        type: null,
        content: null,
        files: null,
        cover: null,
        tagId: null,
        tagTitle: null,
        location: null,
        province: null,
        city: null,
        area: null,
        address: null,
        state: null,
        rejectCause: null,
        duration: null,
        viewTotal: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDynamic({"id":id}).then(response => {
        this.form = response.data
        this.rejectCause = this.form.rejectCause
        this.form.rejectCause = null
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDynamic(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDynamic(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style>
.image-preview-container {
  position: fixed; /* 或 absolute，取决于你的布局需求 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* 半透明黑色背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 确保这个值是最高的 */
}

.image-preview {
  max-width: 90%;
  max-height: 90%;
}
</style>
